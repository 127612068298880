// The whole point of this is there is the backend OpenAPI spec and is reporting wrong (Returned response !== OpenAPI spec)
// So there is just a way so that I can keep the type generation in tact without me losing the actual correct Zod objects
import { z } from "zod";

/**
 * Schema for an ingredient object.
 * @property {Object} ingredient - The ingredient details.
 * @property {string} ingredient.name - The name of the ingredient.
 * @property {string|null} ingredient.measurement_unit - The measurement unit of the ingredient (nullable).
 * @property {number|null} quantity - The quantity of the ingredient (nullable).
 * The quantity must be a valid number or null.
 */
const ingredientSchemaStatic = z.object({
  ingredient: z.object({
    name: z.string(),
    measurement_unit: z.string().nullable(),
  }),
  // Note if the quantity was 0 without the isNan check it would fail
  quantity: z
    .number()
    .nullable()
   // .refine((val) => (val !== null ? !isNaN(parseInt(val.toString())) : null)),
});

/**
 * Schema for an instruction object.
 * @property {number} step - The step number of the instruction.
 * @property {string} description - The description of the instruction step.
 */
const instructionSchemaStatic = z.object({
  step: z.number(),
  description: z.string(),
});

/**
 * Schema for a cocktail object.
 * @property {Array} ingredients - An array of ingredient objects for the cocktail.
 * @property {Array} instructions - An array of instruction objects for the cocktail.
 */
const CocktailSchemaStatic = z.object({
  ingredients: z.array(ingredientSchemaStatic),
  instructions: z.array(instructionSchemaStatic),
});

export { CocktailSchemaStatic, ingredientSchemaStatic, instructionSchemaStatic };