import { makeApi, Zodios, type ZodiosOptions } from "@zodios/core";
import { z } from "zod";
import {
  CocktailSchemaStatic,
  ingredientSchemaStatic,
  instructionSchemaStatic,
} from "./staticZodObjects";

const DrinkStrengthEnum = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
]);
const Bar = z
  .object({
    id: z.number().int(),
    created_at: z.string().datetime({ offset: true }),
    updated_at: z.string().datetime({ offset: true }),
    name: z.string().max(200),
    address: z.string(),
    lat: z.number().nullish(),
    lng: z.number().nullish(),
    place_id: z.string().max(255),
    icon: z.string().max(200).url().optional(),
    price_level: DrinkStrengthEnum.optional(),
    rating: z.number().optional(),
    our_rating: z.number().optional(),
    user_ratings_total: z
      .number()
      .int()
      .gte(-2147483648)
      .lte(2147483647)
      .optional(),
    menu_url: z.string().max(200).url().optional(),
    photos: z.string().url().optional(),
    raw_data: z.object({}).partial().passthrough().nullish(),
    website_url: z.string().max(200).url().nullish(),
    bad_for_ocr: z.boolean().optional(),
    no_menu: z.boolean().optional(),
    has_daily_drink_specials: z.boolean().optional(),
    pdf_menu: z.string().url().nullish(),
    pdf_start_string: z.string().max(255).nullish(),
    pdf_end_string: z.string().max(255).nullish(),
    filter_strings: z.string().nullish(),
    menu_images: z.array(z.number()).optional(),
  })
  .passthrough();
const PaginatedBarList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Bar),
  })
  .partial()
  .passthrough();
const BarLocation = z
  .object({
    lat: z.number(),
    lng: z.number(),
    name: z.string(),
    address: z.string(),
    place_id: z.string(),
    price_level: z.number().int(),
    rating: z.number(),
    user_ratings_total: z.number().int(),
  })
  .passthrough();
const ClassicCocktailList = z
  .object({
    id: z.number().int(),
    name: z.string().max(256),
    description: z.string().nullish(),
    image_url: z.string().max(500).url().nullish(),
    description_for_image_generation: z.string().nullish(),
  })
  .passthrough();
const PaginatedClassicCocktailListList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(ClassicCocktailList),
  })
  .partial()
  .passthrough();

const CocktailNameDescription = z
  .object({ name: z.string().max(200), description: z.string() })
  .passthrough();

const Cocktail = z
  .object({
    id: z.string().uuid(),
    created_at: z.string().datetime({ offset: true }),
    updated_at: z.string().datetime({ offset: true }),
    name: z.string().max(200),
    description: CocktailNameDescription,
    recipe: CocktailSchemaStatic,
    slug: z
      .string()
      .max(50)
      .regex(/^[-a-zA-Z0-9_]+$/)
      .nullish(),
    image_url: z.string().max(500).url().nullish(),
    user: z.number().int().nullish(),
    bar: z.number().int().nullish(),
    user_response: z.number().int().nullish(),
    ingredients: ingredientSchemaStatic.array(),
    instructions: instructionSchemaStatic.array(),
  })
  .passthrough();

const CocktailIngredientsObject = z.object({
  ingredients: z.array(z.string()),
  instructions: z.object({}).partial().passthrough(),
});
const PaginatedCocktailList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Cocktail),
  })
  .partial()
  .passthrough();
const CocktailImageUrl = z
  .object({ image_url: z.string().max(500).url().nullable() })
  .partial()
  .passthrough();

const AllergyDislike = z
  .object({ id: z.number().int(), name: z.string().max(100) })
  .passthrough();
const PaginatedAllergyDislikeList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(AllergyDislike),
  })
  .partial()
  .passthrough();
const BaseAlcohol = z
  .object({ id: z.number().int(), name: z.string().max(100) })
  .passthrough();
const PaginatedBaseAlcoholList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(BaseAlcohol),
  })
  .partial()
  .passthrough();
const FlavorProfile = z
  .object({ id: z.number().int(), name: z.string().max(100) })
  .passthrough();
const PaginatedFlavorProfileList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(FlavorProfile),
  })
  .partial()
  .passthrough();
const Fruit = z
  .object({ id: z.number().int(), name: z.string().max(100) })
  .passthrough();
const PaginatedFruitList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Fruit),
  })
  .partial()
  .passthrough();
const Liqueur = z
  .object({ id: z.number().int(), name: z.string().max(100) })
  .passthrough();
const PaginatedLiqueurList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Liqueur),
  })
  .partial()
  .passthrough();
const SoftDrink = z
  .object({ id: z.number().int(), name: z.string().max(100) })
  .passthrough();
const PaginatedSoftDrinkList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(SoftDrink),
  })
  .partial()
  .passthrough();
const TexturePreference = z
  .object({ id: z.number().int(), name: z.string().max(100) })
  .passthrough();
const PaginatedTexturePreferenceList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(TexturePreference),
  })
  .partial()
  .passthrough();
const CocktailTypeEnum = z.enum(["classic", "custom"]);
const NullEnum = z.unknown();
const UserResponse = z
  .object({
    id: z.number().int(),
    allergies_dislikes: z.array(z.number()).optional(),
    fruits: z.array(z.number()).optional(),
    cocktail_type: z.union([CocktailTypeEnum, NullEnum]).nullish(),
    sweetness_level: z.union([DrinkStrengthEnum, NullEnum]).nullish(),
    is_blended: z.boolean().nullish(),
    is_slushy: z.boolean().nullish(),
    use_fresh_fruit: z.boolean().nullish(),
    drink_strength: z.union([DrinkStrengthEnum, NullEnum]).nullish(),
    has_garnish: z.boolean().nullish(),
    base_alcohol: z.number().int().nullish(),
    liqueur: z.number().int().nullish(),
    soft_drink: z.number().int().nullish(),
    flavor_profile: z.number().int().nullish(),
    texture_preference: z.number().int().nullish(),
  })
  .passthrough();
const CocktailId = z.object({ id: z.string().uuid() }).passthrough();
const GeneratedCocktail = z
  .object({
    name: z.string().max(200),
    description: z.string(),
    recipe: z.object({}).partial().passthrough().nullish(),
  })
  .passthrough();
const OCRImage = z.object({ image: z.string().url() }).passthrough();
const CocktailsList = z
  .object({ cocktails: z.array(z.string()) })
  .passthrough();

export const schemas = {
  DrinkStrengthEnum,
  Bar,
  PaginatedBarList,
  BarLocation,
  ClassicCocktailList,
  PaginatedClassicCocktailListList,
  Cocktail,
  PaginatedCocktailList,
  CocktailImageUrl,
  CocktailNameDescription,
  AllergyDislike,
  PaginatedAllergyDislikeList,
  BaseAlcohol,
  PaginatedBaseAlcoholList,
  FlavorProfile,
  PaginatedFlavorProfileList,
  Fruit,
  PaginatedFruitList,
  Liqueur,
  PaginatedLiqueurList,
  SoftDrink,
  PaginatedSoftDrinkList,
  TexturePreference,
  PaginatedTexturePreferenceList,
  CocktailTypeEnum,
  NullEnum,
  UserResponse,
  CocktailId,
  GeneratedCocktail,
  OCRImage,
  CocktailsList,
  CocktailIngredientsObject,
};

const endpoints = makeApi([
  {
    method: "get",
    path: "/api/bars_with_similar_cocktails/:cocktail_id/",
    alias: "bars_with_similar_cocktails_retrieve",
    requestFormat: "json",
    parameters: [
      {
        name: "cocktail_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.array(BarLocation),
  },
  {
    method: "get",
    path: "/api/bars/",
    alias: "bars_list",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedBarList,
  },
  {
    method: "get",
    path: "/api/bars/:id/",
    alias: "bars_retrieve",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
    ],
    response: Bar,
  },
  {
    method: "get",
    path: "/api/classiccocktails/",
    alias: "classiccocktails_list",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedClassicCocktailListList,
  },
  {
    method: "get",
    path: "/api/classiccocktails/:id/",
    alias: "classiccocktails_retrieve",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.number().int(),
      },
    ],
    response: ClassicCocktailList,
  },
  {
    method: "get",
    path: "/api/cocktails/",
    alias: "cocktails_list",
    requestFormat: "json",
    parameters: [
      {
        name: "has_bar",
        type: "Query",
        schema: z.boolean().optional(),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedCocktailList,
  },
  {
    method: "get",
    path: "/api/cocktails/:id/",
    alias: "cocktails_retrieve",
    requestFormat: "json",
    parameters: [
      {
        name: "has_bar",
        type: "Query",
        schema: z.boolean().optional(),
      },
      {
        name: "id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: Cocktail,
  },
  {
    method: "get",
    path: "/api/get_cocktail_image_url/:cocktail_id/",
    alias: "get_cocktail_image_url_retrieve",
    requestFormat: "json",
    parameters: [
      {
        name: "cocktail_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z
      .object({ image_url: z.string().max(500).url().nullable() })
      .partial()
      .passthrough(),
  },
  {
    method: "get",
    path: "/api/get_cocktail_name_description/:cocktail_id/",
    alias: "get_cocktail_name_description_retrieve",
    requestFormat: "json",
    parameters: [
      {
        name: "cocktail_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: CocktailNameDescription,
  },
  {
    method: "get",
    path: "/api/get_cocktail_recipe/:cocktail_id/",
    alias: "get_cocktail_recipe_retrieve",
    requestFormat: "json",
    parameters: [
      {
        name: "cocktail_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: CocktailSchemaStatic.partial(),
  },
  {
    method: "get",
    path: "/api/questionnaire/allergy_dislike/",
    alias: "questionnaire_allergy_dislike_list",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedAllergyDislikeList,
  },
  {
    method: "get",
    path: "/api/questionnaire/base_alcohol/",
    alias: "questionnaire_base_alcohol_list",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedBaseAlcoholList,
  },
  {
    method: "get",
    path: "/api/questionnaire/flavor_profile/",
    alias: "questionnaire_flavor_profile_list",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedFlavorProfileList,
  },
  {
    method: "get",
    path: "/api/questionnaire/fruit/",
    alias: "questionnaire_fruit_list",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedFruitList,
  },
  {
    method: "get",
    path: "/api/questionnaire/liqueur/",
    alias: "questionnaire_liqueur_list",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedLiqueurList,
  },
  {
    method: "get",
    path: "/api/questionnaire/soft_drink/",
    alias: "questionnaire_soft_drink_list",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedSoftDrinkList,
  },
  {
    method: "get",
    path: "/api/questionnaire/texture_preference/",
    alias: "questionnaire_texture_preference_list",
    requestFormat: "json",
    parameters: [
      {
        name: "page",
        type: "Query",
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedTexturePreferenceList,
  },
  {
    method: "post",
    path: "/api/questionnaire/user_response/",
    alias: "questionnaire_user_response_create",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UserResponse,
      },
    ],
    response: z.object({ id: z.string().uuid() }).passthrough(),
  },
  {
    method: "get",
    path: "/api/search-cocktails/",
    alias: "search_cocktails_list",
    description: `A simple ViewSet for searching Cocktails.`,
    requestFormat: "json",
    parameters: [
      {
        name: "location",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "page",
        type: "Query",
        schema: z.number().int().optional(),
      },
      {
        name: "query",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: PaginatedCocktailList,
  },
  {
    method: "post",
    path: "/api/v1/cocktails/generate/:cocktail_id/",
    alias: "v1_cocktails_generate_create",
    requestFormat: "json",
    parameters: [
      {
        name: "cocktail_id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: GeneratedCocktail,
  },
  {
    method: "post",
    path: "/api/v1/cocktails/ocr/",
    alias: "v1_cocktails_ocr_create",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ image: z.string().url() }).passthrough(),
      },
    ],
    response: CocktailsList,
  },
]);

export const api = new Zodios("https://api.shaken.ai", endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
