import { twMerge } from "tailwind-merge";

export function TypographyH1({
  text,
  className,
}: {
  text: string;
  className?: string;
}) {
  const combinedClassName = twMerge(
    "scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl",
    className
  );
  return <h1 className={combinedClassName}>{text}</h1>;
}

export function TypographyH2({
  text,
  className,
}: {
  text: string;
  className?: string;
}) {
  const combinedClassName = twMerge(
    "scroll-m-20 text-3xl font-semibold tracking-tight transition-colors first:mt-0",
    className
  );
  return <h2 className={combinedClassName}>{text}</h2>;
}
export function TypographyH3({ text }: { text: string }) {
  return (
    <h3 className="scroll-m-20 text-2xl font-semibold tracking-tight">
      {text}
    </h3>
  );
}

export function TypographyH4({ text }: { text: string }) {
  return (
    <h4 className="scroll-m-20 text-xl font-semibold tracking-tight">{text}</h4>
  );
}

export function TypographyP({ text }: { text: string }) {
  return <p className="leading-7 [&:not(:first-child)]:mt-6">{text}</p>;
}

export function TypographyTable({
  rows,
  columns,
  rowClassName,
}: {
  rows: any[];
  columns: any[];
  rowClassName?: string;
}) {
  return (
    <div className="my-6 w-full overflow-y-auto">
      <table className="w-full">
        <thead>
          <tr className="m-0 border-t p-0 even:bg-muted">
            {columns.map((column, index) => (
              <th
                key={index}
                className="border px-4 py-2 text-left font-bold [&[align=center]]:text-center [&[align=right]]:text-right"
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr
              className={twMerge(
                "m-0 border-t p-0 even:bg-muted",
                rowClassName
              )}
              key={rowIndex}
            >
              {columns.map((column, columnIndex) => (
                <td
                  key={row[column]}
                  className="border px-4 py-2 text-left [&[align=center]]:text-center [&[align=right]]:text-right"
                >
                  {row[column]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function TypographyList({ items }: { items: string[] }) {
  return (
    <ul className="my-6 ml-6 list-disc [&>li]:mt-2">
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
}
export function TypographyBlockquote({ text }: { text: string }) {
  return (
    <blockquote className="mt-6 border-l-2 pl-6 italic">{text}</blockquote>
  );
}

export function TypographyLead({ text }: { text: string }) {
  return <p className="text-xl text-muted-foreground">{text}</p>;
}

export function TypographyLarge({
  text,
  className,
}: {
  text: string;
  className?: string;
}) {
  const combinedClassName = twMerge("text-lg font-semibold", className);
  return <div className={combinedClassName}>{text}</div>;
}

export function TypographySmall({ text }: { text: string }) {
  return <small className="text-sm font-medium leading-none">{text}</small>;
}

export function TypographyMuted({ text }: { text: string }) {
  return <p className="text-sm text-muted-foreground">{text}</p>;
}
